body {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #F7EED3;
  color: #674636;

  text-align: center;
}

h1 {
  font-size: max(3.5vw, 4vh);
}

h2 {
  font-size: max(2.5vw, 3vh);
}

ul {  
  margin: 0vh 15vw;
  text-align: left;
}

li {
  font-size: max(1.2vw, 1.6vh);
}

a {
  color: #674636;

  text-decoration: none;

  transition-duration: 0.3s;
}

a:hover {
  color: #67463680;
}

input {
  width: 80%;
  padding: 10px;
  margin-right: 10px;
  margin-top: 0;
}

button {
  width: 10%;
  padding: 10px;
  margin-left: 10px;
  margin-top: 0;
}